import React, { useState, useEffect } from 'react';
import { BaseModal } from './BaseModal';
import { isMobile } from 'react-device-detect';
import { Icon } from '~/components/common/Icon';
import { getTailwindColor } from '~/utils/style/tailwind';

interface DeviceUnsupportedModalProps {}

const DeviceUnsupportedModal: React.FC<DeviceUnsupportedModalProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isMobile) {
      setIsOpen(true);
    }
  }, []);

  return (
    <BaseModal styleOverride='mx-5' closeModal={() => {}} isOpen={isOpen}>
      <div className='flex flex-col items-center justify-center w-full p-5 py-[2.75rem] space-y-[1.125rem]'>
        <div className='scale-[1.9]'>
          <Icon name='warningTriangle' color={getTailwindColor('functional-red')} />
        </div>
        <div className='text-white space-grotesk-bold font-bold text-center leading-tight text-[1.5rem] max-w-[80%] translate-y-1.5'>
          We don`t support mobile devices yet 😢
        </div>
        <div className='text-night1 text-lg space-grotesk-regular leading-tight text-center translate-y-2'>
          Please sign in on a desktop computer to play
        </div>
      </div>
    </BaseModal>
  );
};

export default DeviceUnsupportedModal;
