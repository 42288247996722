import { createContext, useContext } from 'use-context-selector';
import { createMultiSelector } from '~hooks/use-context-selector-helper/createMultiSelector';

export type EventListener = (event: string, data: any) => void;

export interface IAuthContext {
  id?: string;
  username?: string;
  displayName?: string;
  bestRegion?: string;

  avatarUrl?: string;
  email?: string;

  isLoggedIn: boolean;
  isReady: boolean;
  isUserCreated: boolean;
  isLoadingUser: boolean;
  isWalletConnected: boolean;

  login: () => Promise<void>;
  logout: () => Promise<void>;

  updateUser: (userUpdate: {
    newUsername: string;
    newDisplayName?: string;
    newBestRegion?: string;
  }) => Promise<void>;
  updateAvatarVersion: (avatarVersion: number) => Promise<void>;
  deleteUser: () => Promise<void>;

  setUsername: (username: string) => void;
  setDisplayName: (optionalName: string) => void;
  setAvatarUrl: (avatarUrl: string | undefined) => void;
  setIsUserCreated: (isUserCreated: boolean) => void;
}

const InitialValues: IAuthContext = {
  id: '',
  username: '',
  displayName: '',
  email: '',
  avatarUrl: '',
  isLoggedIn: false,
  isReady: false,
  isUserCreated: false,
  isLoadingUser: false,
  isWalletConnected: false,
  login: async () => {
    throw new Error('login not implemented');
  },
  logout: async () => {
    throw new Error('logout not implemented');
  },
  updateUser: async () => {
    throw new Error('updateUser not implemented');
  },
  deleteUser: async () => {
    throw new Error('deleteUser not implemented');
  },
  setUsername: () => {
    throw new Error('setUsername not implemented');
  },
  setDisplayName: () => {
    throw new Error('setDisplayName not implemented');
  },
  setAvatarUrl: () => {
    throw new Error('setAvatarUrl not implemented');
  },
  setIsUserCreated: () => {
    throw new Error('setIsUserCreated not implemented');
  },
  updateAvatarVersion: () => {
    throw new Error('setAvatarVersion not implemented');
  },
};

export const AuthContext = createContext<IAuthContext>(InitialValues);

export const useAuthCtx = () => {
  console.warn('⚠️ useAuthCtx is deprecated, use useMigrateAuthSelector instead');
  return useContext(AuthContext);
};

export const useAuthSelector = createMultiSelector<IAuthContext>(AuthContext);
