import React from 'react';
import { LayoutAuthFlow } from '../layouts/LayoutAuthFlow';
import BrowserUnsupportedModal from '../modals/BrowserUnsupportedModal';
import DeviceUnsupportedModal from '../modals/DeviceUnsupportedModal';
import { useAutoLoginModal } from '~/hooks/auth/useAutoLoginModal';

interface LoginPageProps {}

export const LoginPage: React.FC<LoginPageProps> = () => {
  useAutoLoginModal();

  return (
    <LayoutAuthFlow>
      <BrowserUnsupportedModal />
      <DeviceUnsupportedModal />
    </LayoutAuthFlow>
  );
};
