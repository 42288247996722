import { animated, useTransition } from '@react-spring/web';
import React from 'react';
import { HeatwaveGradient } from '~/components/common/HeatwaveGradient';
import { useOnEscapeKey } from '~/hooks/events/useOnKey';
import Image from 'next/legacy/image';
import { Icon } from '~/components/common/Icon';

interface BaseModalProps {
  children: React.ReactNode;
  background?: JSX.Element;
  isOpen: boolean;
  closeButton?: boolean;
  showGradients?: boolean;
  closeModal: () => void;
  styleOverride?: string;
  cursorOverride?: string;
  disableEscKey?: boolean;
}

export const BaseModal: React.FC<BaseModalProps> = ({
  children,
  background,
  showGradients = false,
  closeButton = false,
  closeModal,
  isOpen,
  styleOverride,
  cursorOverride,
  disableEscKey,
}) => {
  const modalAnimation = useTransition(isOpen, {
    from: { opacity: 0, top: '-3%' },
    enter: { opacity: 1, top: '0%' },
    leave: { opacity: 0, top: '-3%' },
    config: {
      duration: 150,
    },
  });

  useOnEscapeKey(closeModal, disableEscKey);

  return modalAnimation((style, isModalOpen) => (
    <>
      {isModalOpen && (
        <animated.div
          style={style}
          className={`fixed inset-0 flex items-center w-full justify-center bg-night8/80  z-[70] ${
            cursorOverride
              ? cursorOverride
              : 'hover:cursor-close-modal before:content-[""]  before:absolute  before:h-[120%] before:w-[120%] before:blur-lg before:bg-[rgba(10,10,10,0.25)] before:-inset-[3%] before:z-[-1] before:pointer-events-none'
          } `}
        >
          <div className='fixed inset-0 z-0' onClick={closeModal} />
          <div className='pointer-events-none'>{background}</div>
          {/* Modal */}
          <div
            className={`relative ${
              showGradients ? 'bg-night6 overflow-hidden' : 'bg-night6/80'
            } flex items-center justify-center shadow-2xl rounded-[1.25rem] z-30 hover:cursor-default
            ${styleOverride}
            `}
          >
            {closeButton && (
              <div
                className='group absolute top-0 right-0 rounded-full bg-night7/80 opacity-80 hover:opacity-100 border-2 border-heatwave6  z-50 w-10 cursor-pointer h-10 flex items-center justify-center transform -translate-y-1/3 translate-x-1/3'
                onClick={closeModal}
              >
                <Icon name='cross' color='heatwave6' />
              </div>
            )}
            {showGradients && (
              <>
                <div className='absolute -top-1/4 left-0 h-full w-full'>
                  <Image
                    src='/assets/community/gradients/dashboardTop.webp'
                    alt='gradient'
                    layout='fill'
                  />
                </div>
                <div className='absolute -bottom-1/3 left-0 h-full w-full'>
                  <Image
                    src='/assets/community/gradients/dashboardBottom.webp'
                    alt='gradient'
                    layout='fill'
                  />
                </div>
              </>
            )}
            {!showGradients && (
              <HeatwaveGradient
                gradient={{ fromOpacity: 30, toOpacity: 15 }}
                opacity={100}
                rounded='rounded-[1.25rem]'
              />
            )}
            <div className='relative z-10 w-full h-full'>{children}</div>
          </div>
        </animated.div>
      )}
    </>
  ));
};
