import Image from 'next/legacy/image';
import React from 'react';

interface LayoutAuthFlowProps {
  children: React.ReactNode;
}

export const LayoutAuthFlow: React.FC<LayoutAuthFlowProps> = ({ children }) => {
  return (
    <div className='h-[calc(100dvh)] md:h-screen w-screen flex text-white overflow-hidden bg-night5 space-grotesk-regular selection:bg-coldsnap4 selection:text-night8'>
      {/* Left Side */}
      <div className='relative flex justify-center bg-night5 w-full'>
        {/* Gradients */}
        <div className='absolute -inset-x-72 top-0 flex justify-center pointer-events-none z-0'>
          <div className='relative w-full h-[44rem] blur-sm z-0'>
            <Image
              src='/assets/communityEditor/gradients/dashboardTop.webp'
              layout='fill'
              alt='Gradient'
              priority={true}
            />
          </div>
        </div>
        <div className='absolute -inset-x-28 bottom-0 flex justify-center pointer-events-none'>
          <div className='relative w-full h-[18rem] blur-sm'>
            <Image
              src='/assets/communityEditor/gradients/dashboardBottom.webp'
              layout='fill'
              alt='Gradient'
              priority={true}
            />
          </div>
        </div>

        {/* Logo */}
        <div className='absolute inset-x-0 top-10 flex items-center justify-center z-10'>
          <div className='relative flex items-center justify-center mt-[0.125rem] ml-[0.5rem]'>
            <Image
              src='/common/brand/logo/logo_text.svg'
              width={300}
              height={48}
              alt='Internet Game Logo with text'
              priority={true}
            />
          </div>
        </div>

        <div className='w-full relative h-[calc(100dvh)] md:h-screen py-10 overflow-y-auto hide-scrollbar flex-none flex flex-col items-center z-10'>
          {children}
        </div>
      </div>
    </div>
  );
};
