import { LOCAL_STORAGE_KEYS } from './constants';

// ---------------------------------------------------------- TIMESTAMPS

// ---------------------------- SIGN IN TIME
export const getSigninTimestamp = () =>
  Number(localStorage.getItem(LOCAL_STORAGE_KEYS.SIGN_IN_TIME) || '0');

export const setSigninTimestamp = () =>
  localStorage.setItem(LOCAL_STORAGE_KEYS.SIGN_IN_TIME, Date.now().toString());

export const clearSigninTimestamp = () => localStorage.removeItem(LOCAL_STORAGE_KEYS.SIGN_IN_TIME);

// ---------------------------- SIGN IN START TIME
export const getSigninStartTimestamp = () =>
  Number(localStorage.getItem(LOCAL_STORAGE_KEYS.SIGN_IN_START_TIME) || '0');

export const setSigninStartTimestamp = () =>
  localStorage.setItem(LOCAL_STORAGE_KEYS.SIGN_IN_START_TIME, Date.now().toString());
