import type { NextPage } from 'next';
import Head from 'next/head';
import { LoginPage } from '~/components/CommunityEditor/pages/LoginPage';
import { LoginPage as LoginPageOld } from '~/components/CommunityEditor/pages/LoginPageOld';
import { useFlagRefactorAuthProvider } from '~/hooks/flags/useFlagRefactorAuthProvider';

interface SignInPageProps {}

const CommunitySignInPage: NextPage<SignInPageProps> = ({}) => {
  const flagAuthProvider = useFlagRefactorAuthProvider();

  return (
    <>
      <Head>
        <title>Login | Internet Game</title>
        <meta property='og:title' content='Login | Internet Game' key='title' />
        <meta
          name='description'
          content='We invent new ways for people to play together to make the internet more fun.'
        />
        <meta
          name='og:description'
          content='We invent new ways for people to play together to make the internet more fun.'
        />
      </Head>

      {flagAuthProvider ? <LoginPage /> : <LoginPageOld />}
    </>
  );
};

export default CommunitySignInPage;
