import { usePrivy } from '@privy-io/react-auth';
import { useEffect } from 'react';
import { browserName, isMobile } from 'react-device-detect';
import { setSigninStartTimestamp } from '~/utils/analytics';
import { SupportedBrowsers } from '~/utils/constants';
import { useAppSelector } from '../redux/useAppSelector';

export const useAutoLoginModal = () => {
  const { login: privyLogin, ready, isModalOpen } = usePrivy();
  const userId = useAppSelector((state) => state.auth.id);

  // Open the privy login modal when the hook mounts
  useEffect(() => {
    if (ready && !userId && !isModalOpen && !isMobile && SupportedBrowsers.includes(browserName)) {
      privyLogin();
      setSigninStartTimestamp(); // for analytics
    }
  }, [isModalOpen, privyLogin, ready, userId]);
};
