import { useEffect } from 'react';

export const useOnKey = (
  key: string,
  event: keyof DocumentEventMap,
  func: () => any,
  disabled?: boolean
) => {
  useEffect(() => {
    const handleCloseKey = (e: Event) => {
      if ((e as KeyboardEvent).key === key && !disabled) func();
    };
    document.addEventListener(event, handleCloseKey, false);
    return () => {
      document.removeEventListener(event, handleCloseKey, false);
    };
  }, [event, func, key, disabled]);
};

// ------------------------------

export const useOnEscapeKey = (func: () => any, disabled?: boolean) => {
  useOnKey('Escape', 'keydown', func, disabled);
};

export const useOnEnterKey = (func: () => any, disabled?: boolean) => {
  useOnKey('Enter', 'keydown', func, disabled);
};

export const useOnCtrlKey = (func: () => any, disabled?: boolean) => {
  useOnKey('Control', 'keydown', func, disabled);
};

export const useReleaseCtrlKey = (func: () => any, disabled?: boolean) => {
  useOnKey('Control', 'keyup', func, disabled);
};
