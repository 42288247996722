import React, { useState, useEffect } from 'react';
import { BaseModal } from './BaseModal';
import { browserName } from 'react-device-detect';
import { HeatwaveGradient } from '~/components/common/HeatwaveGradient';
import Image from 'next/legacy/image';
import { SupportedBrowsers } from '~/utils/constants';

interface BrowserUnsupportedModalProps {}

const BrowserUnsupportedModal: React.FC<BrowserUnsupportedModalProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    if (!SupportedBrowsers.includes(browserName)) {
      setIsOpen(true);
    }
  }, []);

  const handleClick = (browser: string) => {
    switch (browser) {
      case 'Chrome':
        window.open('https://www.google.com/chrome/');
        break;
      case 'Firefox':
        window.open('https://www.mozilla.org/firefox/');
        break;
      case 'Edge':
        window.open('https://www.microsoft.com/edge/download');
        break;
      case 'Opera':
        window.open('https://www.opera.com/download');
        break;
    }
  };

  return (
    <BaseModal isOpen={isOpen} closeModal={() => {}} cursorOverride='hover:cursor-default'>
      <div className='relative p-10 flex items-center flex-col  antialiased gap-y-5'>
        <div className='text-[2rem] space-grotesk-regular font-bold text-white text-center leading-normal -translate-y-4'>
          {browserName} not supported!
        </div>
        <div className='flex items-center flex-col'>
          <div className='text-night1 text-xl space-grotesk-regular leading-normal -translate-y-2.5'>
            Our games perform <strong className='text-night0 font-bold'>3X better</strong> on the
            browsers below.
          </div>
          <div className='text-night1 text-xl space-grotesk-regular leading-normal -translate-y-3.5'>
            Switch or download to play!
          </div>
        </div>
        <div className='flex gap-x-[1.875rem]'>
          {SupportedBrowsers.map((browser, index) => {
            return (
              <div className='relative group' key={index}>
                <div className='absolute h-[102%] w-[102%] bg-transparent -left-[1%] -top-[1%] border-2 rounded-[11px] border-transparent group-hover:border-coldsnap4 border-solid p-2' />
                <div
                  onClick={() => handleClick(browser)}
                  className='h-[128px] w-[128px] relative rounded-[10px] overflow-hidden shadow-button-inset backdrop-blur-[15px] flex items-center justify-center flex-col space-y-2 group-hover:cursor-pointer border-2 border-transparent  group-hover:shadow-coldsnap-shadow transition-all ease-in-out duration-75'
                >
                  <HeatwaveGradient
                    heatwaveType='heatwave6'
                    gradient={{ fromOpacity: 45, toOpacity: 36 }}
                  />
                  <div className='relative h-[64px] w-[64px]'>
                    <Image
                      src={`/common/icons/${browser.toLowerCase()}.svg`}
                      alt='chrome logo'
                      layout='fill'
                    />
                  </div>
                  <div className='relative text-[17px] space-grotesk-regular text-night1 text-center uppercase [text-shadow:_0px_2px_2px_rgb(0_0_0_/_10%)] group-hover:text-coldsnap4 group-hover:text-shadow-coldsnap-shadow transition-all ease-in-out duration-75'>
                    Download
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BaseModal>
  );
};

export default BrowserUnsupportedModal;
