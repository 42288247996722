import { Context, useContextSelector } from 'use-context-selector';

// In use-context-selector you cannot select multiple values with one selector
// Instead you have to select them individually like this:
// const value1 = useContextSelector(context, (state) => state.value1)
// const value2 = useContextSelector(context, (state) => state.value2)
// const value3 = useContextSelector(context, (state) => state.value3)
// This helper functions simplifies it by returning and object with the values inside
// const { value1, value2, value3 } = useMultiSelector(context, ["value1", "value2", "value3"])

export function createMultiSelector<ContextInterface>(context: Context<ContextInterface>) {
  const useMultiSelector = (stateKeys: (keyof ContextInterface)[]) => {
    const values: Record<any, any> = {};

    stateKeys.forEach(
      (k) =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        (values[k] = useContextSelector(context, (state) => state[k]))
    );

    return values as ContextInterface;
  };

  return useMultiSelector;
}
