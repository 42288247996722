import { IAuthContext, useAuthSelector } from '~/providers/AuthProvider/AuthContext';
import { useFlagRefactorAuthProvider } from '../flags/useFlagRefactorAuthProvider';
import { useAppSelector } from '../redux/useAppSelector';

export const useMigrateAuthSelector = (stateKeys: (keyof IAuthContext)[]): IAuthContext => {
  const flagRefactoredAuthProvider = useFlagRefactorAuthProvider();

  const res = useAuthSelector(stateKeys);

  const id = useAppSelector((state) => state.auth.id);
  const username = useAppSelector((state) => state.auth.username);
  const displayName = useAppSelector((state) => state.auth.displayName);
  const email = useAppSelector((state) => state.auth.email);
  const avatarUrl = useAppSelector((state) => state.auth.avatarUrl);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const isReady = useAppSelector((state) => state.auth.isReady);
  const isUserCreated = useAppSelector((state) => !state.auth.isNewLogin);
  const isLoadingUser = useAppSelector((state) => state.auth.isLoading.getUserByExternalId);

  if (flagRefactoredAuthProvider) {
    return {
      id,
      username,
      displayName,
      email,
      avatarUrl,
      isLoggedIn,
      isReady,
      isUserCreated,
      isLoadingUser,
      isWalletConnected: false,
      login: async () => {
        throw new Error('login is DEPRECATED');
      },
      logout: async () => {
        throw new Error('logout is DEPRECATED');
      },
      updateUser: async () => {
        throw new Error('updateUser is DEPRECATED');
      },
      deleteUser: async () => {
        throw new Error('deleteUser is DEPRECATED');
      },
      setUsername: () => {
        throw new Error('setUsername is DEPRECATED');
      },
      setDisplayName: () => {
        throw new Error('setDisplayName is DEPRECATED');
      },
      setAvatarUrl: () => {
        throw new Error('setAvatarUrl is DEPRECATED');
      },
      setIsUserCreated: () => {
        throw new Error('setIsUserCreated is DEPRECATED');
      },
      updateAvatarVersion: () => {
        throw new Error('setAvatarVersion is DEPRECATED');
      },
    };
  } else {
    return res;
  }
};
