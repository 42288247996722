import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { useMigrateAuthSelector } from '~/hooks/auth/useMigrateAuthSelector';
import { LayoutAuthFlow } from '../layouts/LayoutAuthFlow';
import BrowserUnsupportedModal from '../modals/BrowserUnsupportedModal';
import DeviceUnsupportedModal from '../modals/DeviceUnsupportedModal';
import { useAutoLoginModal } from '~/hooks/auth/useAutoLoginModal';

interface LoginPageProps {}

export const LoginPage: React.FC<LoginPageProps> = () => {
  // ------------------------------------------------- HOOKS
  const router = useRouter();
  useAutoLoginModal();

  // ------------------------------------------------- STATE
  const { username, isReady } = useMigrateAuthSelector(['username', 'isReady']);

  const isNavigating = useRef(false);
  useEffect(() => {
    if (!isNavigating.current && isReady && username) {
      isNavigating.current = true;

      router.push(router.query.redirectTo ? (router.query.redirectTo as string) : '/dashboard');
    }
  }, [router, username, isReady, isNavigating]);

  return (
    <LayoutAuthFlow>
      <BrowserUnsupportedModal />
      <DeviceUnsupportedModal />
    </LayoutAuthFlow>
  );
};
